@font-face {
  font-family: 'proxima-nova';
  src: url('./assets/fonts/ProximaNova-Black.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'proxima-nova';
  src: url('./assets/fonts/ProximaNova-Extrabold.ttf') format('truetype');
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: 'proxima-nova';
  src: url('./assets/fonts/ProximaNova-Semibold.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'proxima-nova';
  src: url('./assets/fonts/ProximaNova-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'proxima-nova';
  src: url('./assets/fonts/ProximaNova-Regular.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'proxima-nova';
  src: url('./assets/fonts/ProximaNova-Regular.ttf') format('truetype');
  font-weight: 200;
  font-style: normal;
}

body {
  margin: 0;
  font-family:proxima-nova, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: proxima-nova;
}
* {
  font-family: proxima-nova;
}
