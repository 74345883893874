// @import url('https://fonts.googleapis.com/css?family=Exo&display=swap');
// bootstrap
@import '~bootstrap/dist/css/bootstrap.min.css';
@import '~react-flags-select/scss/react-flags-select.scss';
@import '~bootstrap-daterangepicker/daterangepicker.css';
@import '~react-toastify/dist/ReactToastify.css';
@import '~react-image-lightbox/style.css';
@import '~react-responsive-carousel/lib/styles/carousel.min.css';
@import '~react-multi-email/style.css';
@import '~video-react/styles/scss/video-react';
@import '~react-perfect-scrollbar/dist/css/styles.css';
@import '~react-select-search/style.css';

// custom style
@import './scss/style.scss';
