.login {
  .title {
    color: #2F4F4F;
    margin-bottom: 0;
    size: 18px;
  }
  .text-title {
    margin-top: 23px;
    font-size: 22px;
    color: #2F4F4F;
  }
  .btnForgot {
    color: #979797;
    size: 14px;
  }
  .btnBack {
    border-radius: 8px;
    border-width: 1px;
    border-color: #2F4F4F;
    color: #2F4F4F;
    min-width: 83px;
    size: 18px;
  }
  .btnNext {
    border-radius: 8px;
    border-width: 1px;
    border-color: #979797;
    color: #979797;
    min-width: 83px;
    size: 18px;
    float: right;
  }

  .btnNextActive {
    border-radius: 8px;
    color: #FFFFFF;
    min-width: 83px;
    size: 18px;
    float: right;
  }
  .checkbox {
    color: #2F4F4F;
    size: 12px;
  }

  .modalResend {
    padding: 3px 18px 44px 18px;
    justify-content: space-between;
    .viewTxt {
      margin-bottom: 18px;
    }
    .txtModal {
      font-size: 18px;
      color: #2F4F4F;
    }
    .viewBottom {
      display: flex;
      position: absolute;
      bottom: 38px;
      right: 38px;
      left: 38px;
      justify-content: space-between;
      .btnDelete {
        width: 150px;
        height: 50px;
        background-color: #F08080;
        border-radius: 8px;
        color: #FFFFFF;
        font-size: 18px;
      }
      .btnYes {
        width: 150px;
        height: 50px;
        background-color: #3DB06A;
        border-radius: 8px;
        color: #FFFFFF;
        font-size: 18px;
      }
      .btnCancel {
        width: 150px;
        height: 50px;
        border: solid 1px #2F4F4F;
        border-radius: 8px;
        color: #2F4F4F;
        font-size: 18px;
      }
    }
  }
}

.modalIsMobile {
  z-index: 1000;
  padding: 3px 18px 22px 18px;
  justify-content: space-between;
  .viewTxt {
    margin-bottom: 8px;
  }
  .txtModal {
    font-size: 16px;
    color: #2f4f4f;
  }
  .viewBottom {
    margin-top: 20px;
    display: flex;
    justify-content: center;
    .btn100 {
      width: 100px;
    }
    .btnDelete {
      width: 220px;
      height: 50px;
      background-color: #3db06a;
      border-radius: 8px;
      color: #ffffff;
      font-size: 18px;
    }
    .btnYes {
      background-color: #3db06a;
      border-radius: 8px;
      color: #ffffff;
      font-size: 18px;
    }
    .btnCancel {
      border: solid 1px #2f4f4f;
      border-radius: 8px;
      color: #2f4f4f;
      font-size: 18px;
    }
  }
}
